@import url(https://use.typekit.net/xld3pqg.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    font-size: 15px;
    line-height: 1.7;
    color: #393F41;
    height: 100vh;
}

main {
    text-align: left;
    width: 95%;
    max-width: 540px;
}

.bio {
    font-family: 'inconsolata', 'Consolas', 'Monaco', monospace;
}

.bio > p {
    text-align: left;
}

.bio > p a {
    color: #ea5455;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 2px solid #ea5455;
    display: inline-block;
    line-height: 1.4;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.bio > p a:hover {
    -webkit-transform: scale(0.96);
            transform: scale(0.96);
}

.portrait {
    height: 140px;
}
